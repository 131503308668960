@import '../../index.scss';

.header {
	align-items: center;
	background-color: $themePrimary;
	display: flex;
	justify-content: space-between;
	margin: 0 0.9375rem 0 0.9375rem;
	min-width: 400px;
	padding: 0.3rem;
}

.ttsLogo {
	background: url(../../assets/Rbb_Logo2.svg) no-repeat 0 center;
	background-size: 80px;
	height: 40px;
	margin-left: 10px;
	width: 33%;
}

.ttsTitle {
	color: white;
	font-size: 24px;
	font-weight: 600;
	width: 33%;
}

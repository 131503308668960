.account {
	color: white;
	margin-top: 10px;
	padding-bottom: 10px;
	> div:nth-child(2) {
		> div:first-child {
			color: white;
		}
		> div:nth-child(2) {
			color: #c6c6c6;
		}
	}
}

body .ttsFont * {
	font-size: 16px;
	font-weight: 400;
}

.ttsRow {
	display: flex;
	flex-wrap: wrap;
	min-width: 415px;
}

.ttsSource {
	flex: 66;
	min-width: 400px;
	padding: 0 0.9375rem;
	width: 66.66%;
	> div {
		padding: 12px 0 12px 0;
	}
	> div > div > div > textarea {
		height: 400px;
	}
}

.ttsSettings {
	flex: 33;
	min-width: 400px;
	padding: 0 0.9375rem;
	width: 33.33%;
	> div:not(:first-child) {
		margin-top: 36px;
	}
	> div:last-child {
		margin-top: 80px;
	}
}

.section {
	> div {
		margin-bottom: 10px;
	}
}

.textfield {
	align-items: center;
	display: flex;
	> div:first-child {
		width: 100%;
	}
	> div:nth-child(2) {
		align-self: flex-end;
	}
}

.errorIcon {
	color: red;
	height: 25px;
	margin-left: 10px;
}
